import styled from 'styled-components';
import {colors} from '../../../constants';

export const Container = styled.div`
  background: linear-gradient(90deg, ${colors.counter_gradient_second_color_9CFF83} 0%, ${colors.counter_gradient_first_color_FCFF5A} 100%);
  border: 3px rgba(255, 255, 255, 0.5) solid;
  border-radius: 8px;
  color: ${colors.black_1A1A1A};

  font-size: 1.5em;
  padding: 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 107px;
  height: 43px;
  box-sizing: border-box;
  backdrop-filter: blur(5px);
`;

export const Title = styled.span`
  font-size: 16px;
  color: ${colors.white};
  font-weight: 700;
`;

export const MainContainer = styled.div`
  border: 3px rgba(255, 255, 255, 0.5) solid;
  border-radius: 11px;
  backdrop-filter: blur(5px);
`
